@import "./components/reset";
@import "animate.css";
@import "react-datepicker/dist/react-datepicker.css";

html {
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  @apply text-black;
  * {
    font-family: MuseoSansCyrl;
  }
}
#root {
  @apply h-full;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker-wrapper {
  @apply w-full h-full flex justify-center items-center;
}

.cardShadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.mobile_iframe {
  width: 375px;
  height: 812px;
  border-radius: 40px;
  @apply bg-gray-500 overflow-hidden;
  &__layout {
    margin-top: 55px;
    width: 100%;
    height: calc(100% - 55px);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}
input,
textarea,
button,
select,
a,
.cursor-pointer,
.touchableOpacity {
  -webkit-tap-highlight-color: transparent;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  height: 28px;
}
.timeInterval {
  font-size: 15px;
}

.fillPulse {
  box-shadow: 0px 0px 10px 0px #36ba5f;
  background: rgba(54, 186, 95, 0.5);
  border-radius: 10px;
  transform: scale(1.05, 1.1);
}

.fooddesc {
  max-height: 82px;
  overflow: hidden;
}

.discount-price {
  position: relative;
}

.discount-price:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 105%;
  height: 1px;
  background-color: #FE312A;
  transform: rotate(-8deg);
}

@import "./components/touchableOpacity";
@import "./components/datepicker/datepicker";
@import "./components/datepicker/timepicker";
@import "./components/ghost";
@import "./components/preloader";
