.customTimePicker {
  @apply rounded-xl overflow-hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  .react-datepicker {
    @apply rounded-none shadow-none;
  }
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  @apply bg-primary;
}