.showSpace {
  box-shadow: inset 0px 0px 0px 1px red;
}
.touchableOpacity {
  @apply inline-block;
  &__inner {
    opacity: 1;
    @apply w-full h-full cursor-pointer;
  }
  &_active {
    .touchableOpacity__inner {
      opacity: 0.5;
    }
  }
}
