.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fdfdfd 8%, #f3f4f6 38%, #fdfdfd 54%);
  background-size: 1000px 640px;
  
  position: relative;
  
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.gradientText {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #f4f4f4 8%, #e5e7eb 38%, #f1f1f1 54%);
  background-size: 1000px 640px;
  
  position: relative;
  
}