
@font-face { 
  font-family: "MuseoSansCyrl";
  src: url("./MuseoSansCyrl500/MuseoSansCyrl500.eot");
  src: url("./MuseoSansCyrl500/MuseoSansCyrl500.eot?#iefix")format("embedded-opentype"),
  url("./MuseoSansCyrl500/MuseoSansCyrl500.woff") format("woff"),
  url("./MuseoSansCyrl500/MuseoSansCyrl500.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face { 
  font-family: "MuseoSansCyrl";
  src: url("./MuseoSansCyrl700/MuseoSansCyrl700.eot");
  src: url("./MuseoSansCyrl700/MuseoSansCyrl700.eot?#iefix")format("embedded-opentype"),
  url("./MuseoSansCyrl700/MuseoSansCyrl700.woff") format("woff"),
  url("./MuseoSansCyrl700/MuseoSansCyrl700.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face { 
  font-family: "MuseoSansCyrl";
  src: url("./MuseoSansCyrl900/MuseoSansCyrl900.eot");
  src: url("./MuseoSansCyrl900/MuseoSansCyrl900.eot?#iefix")format("embedded-opentype"),
  url("./MuseoSansCyrl900/MuseoSansCyrl900.woff") format("woff"),
  url("./MuseoSansCyrl900/MuseoSansCyrl900.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}


@font-face { 
  font-family: "SansProRegular";
  src: url("./SansPro/FontsFree-Net-SFProText-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face { 
  font-family: "SansProMedium";
  src: url("./SansPro/FontsFree-Net-SFProText-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}