.react-datepicker {
  * {
    outline: none !important;
  }
  @apply border-none rounded-xl;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  &__day {
    &-names {
      @apply border-t border-hr border-solid uppercase pt-3;
      .react-datepicker__day-name {
        @apply text-datepicker;
      }
    }
    &--weekend {
      @apply text-primary;
    }
    &--outside-month {
      @apply text-datepicker;
    }
    &:hover {
      @apply bg-primary text-white rounded-full opacity-50;
    }
    &--selected {
      @apply bg-primary text-white rounded-full;
      &:hover {
        @apply bg-primary text-white rounded-full opacity-50;
      }
    }
  }
  &__header {
    @apply bg-transparent border-none py-0;
    &__dropdown {
      @apply flex pl-3 h-14 text-15px items-center;
    }
  }
  &__triangle {
    @apply hidden;
  }
  &__navigation {
    @apply absolute h-14;
    &-icon {
      @apply hidden;
    }
    &--previous {
      @apply right-8 left-auto;
      &:before {
        content: "";
        @apply w-2 h-3 bg-contain bg-center bg-no-repeat;
        background-image: url(arrows/left.svg);
      }
    }
    &--next {
      @apply right-0;
      &:before {
        content: "";
        @apply w-2 h-3 bg-contain bg-center bg-no-repeat;
        background-image: url(arrows/right.svg);
      }
    }
    &-icon {
      &:before {
        @apply border-black;
      }
    }
  }
  &__current {
    &-month {
      @apply hidden;
    }
  }
}

.react-datepicker__month-year-read-view--down-arrow {
  @apply hidden;
}
.react-datepicker__month-year-read-view--selected-month-year {
  @apply capitalize;
  &:after {
    content: "";
    @apply ml-2 h-2 w-3 bg-contain bg-center bg-no-repeat inline-block;
    background-image: url(arrows/bottom.svg);
  }
}

.react-datepicker__month-year-read-view {
  visibility: visible !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply mx-2;
}

.react-datepicker__day--excluded {
  @apply bg-red-500 rounded-full text-black opacity-30;
}
