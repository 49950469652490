.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: transparent;
}

.circle-progress {
  stroke: #2daa56;
}

.circle-progress {
  animation: progress 4s ease-in-out infinite;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 141;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -141;
  }
}
